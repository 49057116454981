<template>
  <H5 v-if="isMobile" />
  <PC v-else />
</template>

<script lang="ts" setup>
import { ENUM_PageName } from '~/enums'

definePageMeta({
  pageCategory: ENUM_PageName.Home,
})

const isMobile = useMobileByScreen()
const { isBurnCoinsScene } = useGlobalStore()

const H5 = defineAsyncComponent(() => isBurnCoinsScene ? import('~/pages/mobile/coins/index.vue') : import('~/pages/mobile/index.vue'))
const PC = defineAsyncComponent(() => isBurnCoinsScene ? import('~/pages/desktop/coins/index.vue') : import('~/pages/desktop/index.vue'))
</script>
